// Scripts for the division category page template
'use strict'

/**
 * Imports SCSS for Webpack parsing and sets strict mode
 */
import '../../../styles/src/pages/division-category.scss';

/*
 * Component imports
 */
// Division row
import '../components/division/row';
// Columned content
import '../components/general/columned-content';
// Division feature
import '../components/division/feature';
// Feature Repeater
import '../components/general/feature-repeater';
// Testimonial promotion
import '../components/promotions/testimonial';
// CTA promotion
import '../components/promotions/calls-to-action';
// Content authorship
import '../components/general/authorship';

/*
 * Page-specific scripting
 */
